@tailwind base;

@tailwind components;

@tailwind utilities;

html,
body {
  @apply bg-gray-300;
}

html.focus-outline-hidden *:focus {
  outline: none;
}

body,
input {
  @apply text-gray-800;
}

button:focus,
*:focus {
  outline: auto theme('colors.orange.500');
}

[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

input::placeholder {
  @apply text-gray-500;
  opacity: 1;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="text"][disabled],
input[type="email"][disabled],
input[type="password"][disabled] {
  background: white;
  appearance: none;
}

mark {
  background-color: yellow;
  color: inherit;
}

.bg-gradient-teal {
  background: linear-gradient(
    to top,
    theme('colors.teal.600'),
    theme('colors.teal.400')
  )
}

.bg-gradient-gray {
  background: linear-gradient(
    to top,
    theme('colors.gray.400'),
    theme('colors.gray.200')
  )
}

/* gray between 100 and 200 */
.bg-gray-150 {
  background-color: #f2f6fa;
}

.flip-horiz {
  transform: scaleY(-1);
}

.trans-transform {
  transition: transform 250ms;
}

.trans-opacity {
  transition: opacity 250ms;
}

.min-h-20 {
  min-height: 5rem;
}

.off-left {
  transform: translateX(-110vw);
}

/* NOTE: doesn't work on <button>s cross-browser,
 *       use e.g. span[role="button"][tabindex=0] */
.show-next-on-focus + * {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 250ms, visibility 250ms, transform 250ms;
  visibility: hidden;
}
.show-next-on-focus + *:focus-within,
.show-next-on-focus:focus + * {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.primary-button {
  @apply block bg-teal-600 border-2 border-teal-600 font-bold;
  @apply px-4 py-2 rounded text-center text-white w-full;
  transition: border-color 250ms, background-color 250ms, box-shadow 250ms;
}
.primary-button:active,
.primary-button:focus {
  @apply shadow-lg;
}
.primary-button:active,
.primary-button:focus,
.primary-button:hover {
  @apply bg-teal-500 border-teal-500;
}

.trans-shadow {
  transition: box-shadow 250ms;
}

.trans-bg-color {
  transition: background-color 250ms;
}

.secondary-button {
  @apply block border-2 border-teal-600 font-bold;
  @apply px-4 py-2 rounded text-center text-teal-600 w-full;
  transition: color 250ms, border-color 250ms, box-shadow 250ms;
}
.secondary-button:active,
.secondary-button:focus {
  @apply shadow-lg;
}
.secondary-button:active,
.secondary-button:focus,
.secondary-button:hover {
  @apply border-teal-500 text-teal-500;
}

.menu-link {
  @apply px-6 py-3 block bg-white;
  @apply text-teal-600 text-sm text-left;
  @apply flex items-center justify-between;
  transition: background 250ms, color 250ms;
}
.menu-link:hover,
.menu-link:focus {
  @apply bg-teal-200;
  @apply text-teal-700;
}

.static-page {
  @apply px-5 py-10 bg-white;
}
.static-page h2 {
  @apply text-xl;
}
.static-page h3 {
  @apply text-lg;
  @apply mt-8 mb-5;
}
.static-page h2:not(:first-child) {
  @apply mt-8 mb-5;
}
.static-page p {
  @apply my-5;
}
.static-page a[href] {
  @apply text-teal-600 underline;
}
.static-page ul {
  list-style: disc;
  margin-left: 1.2em;
}
